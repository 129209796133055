<template>
	<div class="content">
		<section class="list-section">
			<div class="container">
				<h1 class="title">Оплата і доставка</h1>
				<div class="list-wrap">
					<ul class="list conunts list-cols-2">
						<li v-for="item in list" :key="item" class="count-item">
							<p v-if="item.descr.length" v-html="item.descr"></p>
						</li>
					</ul>
				</div>
			</div>
		</section>
		<section>
			<v-contacts/>
		</section>
	</div>
</template>

<style scoped lang="scss">
	@import "../assets/scss/components/delivery-page.scss" 
</style>

<script>
	import Contacts from '@/components/Contacts.vue'

	export default {
		components: {
			'v-contacts': Contacts
		},
		data(){
			return {
				list:[
					{
						descr: 'Придбати рослини з асортименту можна у нашому садовому центрі за адресою: с. Солонка, вул. Стрийська, 1Б'
					},
					{
						descr: '<b>Важливо!</b> Не відправляємо рослини поштою.'
					},
					{
						descr: 'Доставку по Україні розраховуємо індивідуально, залежно від типу та об\'єму замовлення.'
					},
					{
						descr: 'У межах Львова доставляємо рослини попередньо замовлені для озеленення або на прохання доставки.'
					}
				],
			}
		}
	}
</script>